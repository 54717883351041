import { motion } from 'framer-motion';
import Image from 'next/image';
import React, { FC, useContext } from 'react';
import useImage from '../../../hooks/use-image/use-image';
import useNextImageLoader from '../../../hooks/use-next-image-loader/use-next-image-loader';
import {
  DataLayerContext,
  DataLayerContextType,
} from '../../providers/data-layer/data-layer.provider';
import GtmEventNameEnum from '../../../models/gtm-event-name-enum';
import { HeroImagePanelSingleType } from '../../../models/hero-image-panel-single-type';
import { LinkType } from '../../../models/link-type';
import ButtonPrimary from '../../atoms/button-primary/button-primary';
import TextLink from '../../atoms/text-link/text-link';

interface Props extends HeroImagePanelSingleType {
  readonly isActive: boolean;
}

const HeroPanelSingle: FC<Props> = ({
  callToAction,
  description,
  heading,
  headingSize,
  image,
  imageAltText,
  isActive,
  links,
}: Props) => {
  const { pushToDataLayer } =
    useContext<DataLayerContextType>(DataLayerContext);
  const responsiveImage = useImage(image);

  const headingComponents = {
    h1: <h1 className="page-heading-one">{heading}</h1>,
    h2: <h2 className="page-heading-two">{heading}</h2>,
  };

  const headlineComponent =
    description || callToAction || (links && headingSize !== 'h1') ? (
      <>{headingComponents.h2}</>
    ) : (
      <>{headingComponents.h1}</>
    );

  const variants = {
    isActive: { opacity: 1 },
    notActive: { opacity: 0 },
  };

  const transition = {
    damping: 40,
    delay: 0.35,
    duration: 0.6,
    mass: 3,
    stiffness: 300,
    type: 'spring',
  };

  return (
    <div className="relative py-12">
      <div className="flex flex-col absolute z-10 inset-0 justify-start md:justify-center">
        <motion.div
          animate={isActive ? 'isActive' : 'notActive'}
          className="w-10/12 md:w-6/12 text-center flex flex-col items-center mx-auto mt-6 md:mt-0"
          transition={transition}
          variants={variants}
        >
          {headlineComponent}
          <p className="text-grey-600 mb-6">{description}</p>
          {links && (
            <ul className="list-outside text-center">
              {links.map((link: LinkType) => {
                return (
                  <li>
                    <TextLink {...link} />
                  </li>
                );
              })}
            </ul>
          )}

          {callToAction && (
            <div className="w-auto">
              <ButtonPrimary
                {...callToAction}
                onClick={() => {
                  pushToDataLayer(GtmEventNameEnum.CategoryFeatureBlock, {
                    title: heading,
                  });
                }}
              />
            </div>
          )}
        </motion.div>
      </div>

      <motion.div
        animate={isActive ? 'isActive' : 'notActive'}
        className="h-96 relative -mt-16 lg:mt-0 inset-0"
        transition={transition}
        variants={variants}
      >
        {responsiveImage && (
          <Image
            layout="fill"
            loader={useNextImageLoader}
            objectFit="cover"
            quality={100}
            src={responsiveImage}
            priority
            alt={imageAltText || 'Hero panel image'}
          />
        )}
      </motion.div>
    </div>
  );
};

export default HeroPanelSingle;

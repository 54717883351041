import React, { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { HeroImagePanelSingleType } from '../../../models/hero-image-panel-single-type';
import { HeroTextImageBannerType } from '../../../models/hero-text-image-banner-type';
import HeroPanelSingle from '../../molecules/hero-panel-single/hero-panel-single';

const HeroTextImageBanner: FC<HeroTextImageBannerType> = ({
  bannerPanels,
}: HeroTextImageBannerType) => {
  return (
    <div className="py-component-spacing-mobile lg:py-component-spacing -mx-4 md:-mx-10 lg:-mx-10 xl:-mx-20">
      <Swiper
        a11y={{ enabled: true }}
        className="py-6"
        loop
        observeParents
        observer
        pagination={{ clickable: true }}
        spaceBetween={20}
        speed={500}
      >
        {bannerPanels.map((panel: HeroImagePanelSingleType) => {
          return (
            <SwiperSlide key={`slide-${panel.heading}`}>
              {({ isActive }) => (
                <HeroPanelSingle isActive={isActive} {...panel} />
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default HeroTextImageBanner;
